<template>
    <header
        ref="header"
        :class="[scrollableClass, stickyHeaderClass]"
        class="header d-flex align-center"
    >
        <v-container>
            <div class="header-content">
                <NuxtLink to="/" class="logo-header">
                    <nuxt-img
                        width="130"
                        height="38.5"
                        alt="0x4447, LLC."
                        src="/public/img/logo_light.svg"
                        class="logo-header-img"
                        format="webp"
                    />
                    <span class="header-logo__text">
                        a solid foundation
                    </span>
                </NuxtLink>
                <client-only>
                    <div
                        v-if="linkToBack"
                        class="header-navbar__item header-navbar__item--back"
                    >
                        <NuxtLink
                            :to="linkToBack"
                            class="header-navbar__item-link"
                        >
                            &#60; Back
                        </NuxtLink>
                    </div>
                </client-only>
                <div class="header-navbar">
                    <ul class="header-navbar__list">
                        <li
                            v-for="item in navBarList"
                            :key="item.name"
                            class="header-navbar__item"
                        >
                            <NuxtLink
                                :to="getSubPageLink(item.url)"
                                class="header-navbar__item-link d-flex align-center"
                            >
                                <nuxt-img
                                    :src="item.imgPath"
                                    :alt="item.name"
                                    width="16"
                                    height="16"
                                    class="header-navbar__item-icon"
                                    loading="lazy"
                                    format="webp"
                                />
                                <span>{{ item.name }}</span>
                            </NuxtLink>
                        </li>
                    </ul>
                    <div class="header-navbar-mobile">
                        <v-menu offset-y min-width="200" max-width="200">
                            <template #activator="{ on, attrs }">
                                <v-btn icon large :aria-label="'menu'" v-bind="attrs" v-on="on">
                                    <v-icon large>
                                        {{ $icons.mdiMenu }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="item in navBarList"
                                    :key="item.name"
                                >
                                    <NuxtLink
                                        :to="getSubPageLink(item.url)"
                                        class="header-navbar__item-link d-flex align-center"
                                    >
                                        <nuxt-img
                                            :src="item.imgPath"
                                            :alt="item.name"
                                            width="16"
                                            height="16"
                                            class="header-navbar__item-icon"
                                            loading="lazy"
                                            format="webp"
                                        />
                                        <span>{{ item.name }}</span>
                                    </NuxtLink>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>
        </v-container>
    </header>
</template>

<script>
const NAVBAR_LIST = [
    {
        name: 'Documentation',
        url: 'product-item-documentation-version',
        imgPath: '/public/img/footer_icons/documentation.png'
    },
    {
        name: 'Support',
        url: 'support',
        imgPath: '/public/img/footer_icons/support.png'
    }
];

export default {
    name: 'TheHeader',
    data() {
        return {
            scrollPosition: 0
        };
    },
    computed: {
        navBarList() {
            return this.$route.params.item ? NAVBAR_LIST : [NAVBAR_LIST[1]];
        },
        isSubPage() {
            return [
                'contact',
                'support',
                'feedback',
                'training',
                'privacy-policy',
                'terms-of-service',
                'evaluation-program',
                'reseller-program',
                'product-item-documentation-version',
                'together',
                'documentation'
            ].includes(this.$route.name);
        },
        linkToBack() {
            const isShowBackButton = process.client
                ? this.isSubPage && Boolean(sessionStorage.getItem('prevProductPageName'))
                : false;
            return isShowBackButton
                ? {
                    name: 'product-item',
                    params: { item: sessionStorage.getItem('prevProductPageName') }
                }
                : null;
        },
        isProductPage() {
            return this.$route.name === 'product-item';
        },
        isDocumentPage() {
            return this.$route.name === 'product-item-documentation-version';
        },
        isStickyHeader() {
            return this.isProductPage || this.isDocumentPage;
        },
        stickyHeaderClass() {
            return this.isStickyHeader ? 'header--sticky' : null;
        },
        scrollableClass() {
            return this.isStickyHeader && this.scrollPosition > 30 ? 'header--scrollable' : null;
        }
    },
    mounted() {
        this.initEventListeners();
    },
    beforeDestroy() {
        this.removeEventListeners();
    },
    methods: {
        initEventListeners() {
            window.addEventListener('scroll', this.handleScroll);
        },
        removeEventListeners() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        getSubPageLink(name) {
            const nuxtLink = { name };

            if (name === NAVBAR_LIST[0].url) {
                nuxtLink.params = {
                    item: this.$route.params.item,
                    version: this.$store.getters.activeLatestDocVersion
                };
            }
            if (process.client) {
                if (this.$route.params.item) {
                    sessionStorage.setItem('prevProductPageName', this.$route.params.item);
                } else if (!this.isSubPage) {
                    sessionStorage.removeItem('prevProductPageName');
                }
            }
            return nuxtLink;
        },
        handleScroll() {
            this.scrollPosition = window.scrollY;
        }
    }
};
</script>

<style lang="scss" src="./header.scss" />
